import React from 'react';

const Footer = () =>{
    return(
        <div className = 'footer-cont'>
            <div>
                William Liu 2020 ©
            </div>
            <div>
                Williamliu204@gmail.com
            </div>
        </div>
    );
};

export default Footer;